import { InlineLoading } from '@wfp/ui';
import './DownloadFileLink.scss';

interface DownloadFileLinkViewProps {
  downloadFile: () => void;
  filename: string;
  downloadedFileUrl: string;
  isDownloading: boolean;
  linkRef: React.MutableRefObject<HTMLAnchorElement | null>;
  label: string;
  showLoading: boolean;
}

export const DownloadFileLinkView = ({
  filename,
  downloadFile,
  isDownloading,
  downloadedFileUrl,
  linkRef,
  label,
  showLoading
}: DownloadFileLinkViewProps) => (
  <div className={`download-file-link__wrapper`}>
    <a
      href={downloadedFileUrl}
      download={filename}
      className="download-file-link__hidden-link"
      ref={linkRef}
    >
      {label}
    </a>
    <div className="download-file-link-tag" onClick={downloadFile}>
      {label}
    </div>
    {showLoading && (
      <InlineLoading className={!isDownloading ? 'hidden' : ''} />
    )}
  </div>
);
