import { ReactNode, useMemo } from 'react';
import { Icon } from '@wfp/ui';
import { iconChevronDown } from '@wfp/icons';
import './AccordionItem.scss';

export interface AccordionItemViewProps {
  children: ReactNode;
  id: number;
  title: ReactNode;
  isExpanded: boolean;
  toggleExpanded: () => void;
  className: string;
  onExpand?: () => void;
}

export const AccordionItemView = ({
  children,
  id,
  title,
  isExpanded,
  toggleExpanded,
  className,
  onExpand
}: AccordionItemViewProps) => {
  const accordionItemClassName = useMemo(
    () =>
      isExpanded
        ? `${className} accordion-item accordion-item--expanded`
        : `${className} accordion-item`,
    [className, isExpanded]
  );

  return (
    <li className={accordionItemClassName} key={`accordion-item-${id}`}>
      <div
        className="accordion-item__header"
        role="button"
        tabIndex={0}
        aria-controls={`content-${id}`}
        aria-expanded={isExpanded ? true : false}
        onClick={() => {
          onExpand && !isExpanded && onExpand();
          toggleExpanded();
        }}
        data-testid="accordion-item"
      >
        {title}
        <Icon
          className={
            isExpanded
              ? 'accordion-item__chevron accordion-item__chevron--rotated'
              : 'accordion-item__chevron'
          }
          icon={iconChevronDown}
          description="Accordion indicator icon"
          width="12"
          height="12"
        />
      </div>
      <div
        id={`content-${id}`}
        className={
          isExpanded
            ? 'accordion-item__panel accordion-item__panel--shown'
            : 'accordion-item__panel'
        }
        aria-hidden={isExpanded ? false : true}
        data-testid="accordion-item-body"
      >
        {children}
      </div>
    </li>
  );
};
